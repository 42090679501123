import { Link } from 'gatsby';
import React from 'react';
import FaqText from './otherlanguage/FaqText';
import PrivacyPolicyText from './otherlanguage/PrivacypolicyText';
import TermsText from './otherlanguage/TermsText';

export default function Footer() {
  return (
    <footer>
      <div className="container">
        <p>&copy; <a className='footertext' href='https://yurikanagawa.com/'>Yuri Kanagawa.</a> All Rights Reserved.</p>
        <ul className="list-inline">
          <li className="list-inline-item" >
            <Link to="/privacy_policy" className='footertext'>
              <PrivacyPolicyText/>
            </Link>
          </li>
          <li className="list-inline-item">
          <Link to="/terms" className='footertext'>
              <TermsText/>
            </Link>
          </li>
          <li className="list-inline-item">
            <Link to="/faq" className='footertext'>
              <FaqText/>
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  );
}
